export enum PageCategory {
  RewardPrograms = 'Reward Programs'
}

export enum GlobalLiquidityTab {
  Liquidity = 'liquidity',
  Scores = 'scores',
  RewardDistribution = 'rewards',
  Eligibility = 'eligibility'
}

export enum MarketHeaderType {
  Market = 'market',
  Liquidity = 'liquidity',
  EpochRewards = 'epoch-rewards',
  Volume = 'volume'
}

export enum ScoreHeaderType {
  LiquidityScore = 'liquidity-score',
  Uptime = 'uptime',
  TotalVolume = 'total-volume',
  TotalScore = 'total-score',
  EstimatedReward = 'estimated-reward'
}

export enum RewardDistributionType {
  EstimatedReward = 'estimated-reward'
}

export enum RewardsDistributionPage {
  MakerContribution = 'maker-contribution',
  Snapshot = 'snapshot'
}

export enum PaginationState {
  InvalidQuery = 'invalid-query',
  QueryPageExist = 'query-page-exist',
  QueryMoreThanTotalPage = 'query-more-than-total-page'
}

export enum DetailsPage {
  Spanshots = 'snapshots'
}

export enum EventsBus {
  ShowNavbar = 'show-nav-bar'
}

export enum NetworkErrorType {
  Epoch = 'Epoch',
  Markets = 'Markets',
  Scores = 'Scores',
  Eligibility = 'Eligibility',
  Snapshots = 'Snapshots',
  RewardsDistribution = 'Rewards Distribution'
}

export enum CsvType {
  AllScores = 'all-scores',
  TotalScore = 'total-score',
  LiquidityScore = 'liquidity-score',
  UptimeScore = 'uptime-score',
  VolumeScore = 'volume-score'
}

export enum WalletConnectStatus {
  Idle = 'Idle',
  Connected = 'Connected',
  Connecting = 'Connecting',
  Disconnected = 'Disconnected'
}

export enum NotifiSteps {
  GetNotifications = 'GetNotifications',
  ManageNotifications = 'ManageNotifications',
  EditDestinations = 'EditDestinations',
  AlertHistory = 'AlertHistory',
  AlertDetails = 'AlertDetails'
}

export enum WalletSteps {
  ConnectWallet = 'ConnectWallet',
  WalletOptions = 'WalletOptions',
  Ledger = 'Ledger',
  Trezor = 'Trezor'
}

export enum NotifiUserState {
  LoggedOut = 'loggedOut',
  Authenticated = 'authenticated',
  Expired = 'expired'
}

export enum MarketStatus {
  Unspecified = 'unspecified',
  Active = 'active',
  Paused = 'paused',
  Demolished = 'demolished',
  Expired = 'expired'
}

export enum Keys {
  InsuranceFunds = 'inj-insurance-funds'
}

export enum Modal {
  CreateInsuranceFund = 'create-insurance-fund',
  WalletConnect = 'wallet-connect',
  Underwrite = 'underwrite',
  RequestRedemption = 'request-redemption'
}

export enum Pages {
  InsuranceFunds = 'insurance-fund',
  PendingRedemptions = 'insurance-fund-pending-redemptions'
}

export enum InsuranceSortType {
  All = 'all',
  Funds = 'funds',
  Market = 'market',
  MyPoolTokens = 'my-pool-tokens',
  TotalPoolTokens = 'total-pool-token'
}
